<template>
	<div class="check-in">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isPortSearchModalOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back/>
		</div><!-- header -->
		<div class="header-image orange"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="CheckInIcon"
				title="Check-in"
				colour="orange"
			/>
			<div class="section-gap section-port-list">
				<div class="section-title-wrap">
					<h2 class="section-title">Recently Visited Ports</h2>
					<router-link :to="{ name: 'CheckInLog' }" class="page-link page-link-orange">View log &raquo;</router-link>
				</div>
				<div class="port-list-wrap section-scroll">
		            <PortListBlock
                        v-for="checkIn in recent"
                        :key="checkIn.id"
		            	:port="checkIn.port.name"
		            	:country="checkIn.port.country"
		            	:date="checkIn.created_at | moment('Do MMMM YYYY')"
                        :id="checkIn.port.id"
                        :countryCode="checkIn.port.country_code"
		            />
				</div><!-- port list wrap -->
			</div>
			<div class="section-gap section-ports">
				<div class="field field-icon field-icon-left">
					<div class="control">
						<input class="search-field" placeholder="Search to Check-in" type="text" @click="openSearchModal">
						<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
					</div>
				</div>
			</div>
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">My Favourite Ports</h2>
                    <router-link :to="{ name: 'MyPorts' }" class="page-link page-link-grey">View all &raquo;</router-link>
				</div>
				<div class="port-card-wrap" v-dragscroll>
                    <PortCard
                        v-for="port in ports"
                        :key="port.id"
                        :country="port.country"
                        :port="port.name"
                        :id="port.id"
                        :image="port.image_url"
                    />
                    <PortCard
                    	className="grey"
                    	port="Add more ports to your list"
                    	add="true"
                        route="OurPorts"
                   	/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap">
				<div class="info-card">
					<span class="eyebrow">Check-in Statistics</span>
					<div class="info-card-wrap">
						<div class="row">
							<p class="p-strong col">Total Check-ins</p>
							<p class="col">{{ stats.total }}</p>
						</div>
						<div class="row">
							<p class="p-strong col">Ports Visited</p>
							<p class="col">{{ stats.visited }}</p>
						</div>
						<div class="row">
							<p class="p-strong col">My Ports</p>
							<p class="col">{{ stats.ports }}</p>
						</div>
					</div>
				</div><!-- info card -->
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="OurPorts"
					class="card-block-blue"
					icon="AnchorIcon"
					category="Ports"
					title="View Our Ports"
					text="Find out where we work across the world"
				/>
				<Card
					routeName="Requests"
					class="card-block-green"
					icon="SendIcon"
					category="Requests"
					title="Make a request"
					text="Pre-order goods and services"
				/>
			</div><!-- card wrap -->
		</div>
		<PortSearchOverlay title="Search to Check-in" active/>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import PortListBlock from '@/components/PortListBlock.vue';
	import PortCard from '@/components/PortCard.vue';
	import SearchIcon from '@/assets/search.svg';
	import Card from '@/components/Card.vue';
	import UserService from '@/services/UserService';
    import PortService from '@/services/PortService';
    import PortSearchOverlay from '@/components/PortSearchOverlay.vue';
	export default {
		name: 'CheckIn',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			PortCard,
			PortListBlock,
			SearchIcon,
			PortSearchOverlay,
			Card
		},
		data () {
	        return {
                recent: [],
	        	ports: [],
                stats: {},
                search: ''
	        };
	    },
	    methods: {
            getCheckIn () {
                return UserService.getCheckIn().then((response) => {
                    this.recent = response.data.recent;
                    this.ports = response.data.ports;
                    this.stats = response.data.stats;
                });
            },
            openSearchModal () {
                this.$store.commit('PORT_SEARCH_TOGGLE');
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isPortSearchModalOpen () {
                return this.$store.state.isPortSearchModalOpen;
            }
		},
		mounted () {
			this.getCheckIn();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
    .search-field {
        padding: 0 50px !important;
    }
</style>
